import React, { useRef } from 'react';
import "./contact.css";
import emailjs from "@emailjs/browser";

const Contact = () => {

    const form = useRef();

    const sendEmail = (e) => {
        e.preventDefault();

        emailjs
            .sendForm(
                "service_n8jnz7k",
                "template_7104c0q",
                form.current,
                "Km6A37hdTYekvVbus"
            )
            .then(
                (result) => {
                    console.log(result.text);
                    alert("Email Send Successfully");
                },
                (error) => {
                    console.log(error.text);
                }
            );
    };

    return (
        <>
            <div className="contact-us-wrapper-container" id='contact'>
                <div className="contact-us-page grid md:grid-cols-2 gap-4 p-7">
                    <div className="contact-us-side-container w-3/10"> {/* 30% width */}
                        <h4 className="contact-us-text">Don't Be shy</h4>
                        <p className="contact-us-para">We would love hearing from you</p>
                    </div>
                    <div className="contact-us-form-container"> {/* 70% width */}
                        <h4 className="contact-us-header">Let's Discuss Your Project</h4>

                        <div className="contact-us-form">
                            <form
                                ref={form}
                                onSubmit={sendEmail}
                                method="post"
                                className="align-middle"
                            >
                                <div className="fomr-field-container">
                                    <input
                                        type="text"
                                        name="user_name"
                                        id="inputID"
                                        className="input-textarea"
                                        placeholder="Name"
                                        required
                                    ></input>
                                    <br />
                                    <input
                                        type="email"
                                        name="user_email"
                                        id="inputID"
                                        className="input-textarea"
                                        placeholder="Email Id"
                                        required
                                    ></input>
                                </div>


                                <br />
                                <textarea
                                    name="message"
                                    id="inputID"
                                    className="input-message"
                                    placeholder="Message"
                                ></textarea>
                                <br />

                                <input
                                    type="submit"
                                    value="Submit"
                                    className="btn btn-container"
                                />

                                {/* <button className='btn btn-container'>
                                                <span className='btn-span'>Contact Us</span>
                                            </button> */}
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Contact
