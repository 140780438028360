import './App.css';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Navbar from './Components/Navbar/Navbar';
import Home from "./Components/Home/Home"
import Services from './Components/Home/Services';
import Huddle from './Components/Home/Huddle';
import Contact from './Components/Home/Contact';

function App() {
  return (
    <>
    <Router>
      <Navbar/>
      <Routes>
        <Route path='/' element={<Home/>}/>
        <Route path='/services' element={<Services/>} scrollBehavior="smooth"/>
        <Route path='/about-us' element={<Huddle/>} scrollBehavior="smooth"/>
        <Route path='/contact-us' element={<Contact/>} scrollBehavior="smooth"/>
      </Routes>
    </Router>
    </>
  );
}

export default App;
