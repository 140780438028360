import React from 'react';
import xavi from "../Assets/xavi.svg"
import hritika from "../Assets/hritika.svg"
import developer from "../Assets/developer.svg";
import cars from "../Assets/car-to-drive.gif"

const Huddle = () => {
    return (
        <>
            <div className="huddle-page-container-wrapper" id='about-us'>
                <h1 className='huddle-header-text'>Overcoming "<span className='huddle-span-text'>New Agency</span>" Hurdle:</h1>
                <div className="huddle-page-wrapper grid grid-cols-1 md:grid-cols-2 p-7 bg-black gap-5">
                    <div className="text-side-data-container">
                        <div className="text-container-home-page">
                            <p className='huddle-page-para-text'>While we may be a new agency, our team boasts extensive experience in delivering uccessful digital solutions for clients across various industries.</p>
                            <p className='huddle-page-para-text'>We believe in a collaborative approach, working closely with you to understand your specific needs and goals. Clear communication and client feedback are at the heart of our process.</p>
                            <p className='huddle-page-para-text'>Schedule a free consultation to discuss your project and receive a tailored proposal.</p>
                        </div>
                    </div>
                    <div className="image-data-container md:h-80">
                    <iframe src='https://my.spline.design/tvatimedoor-2ff7fe17281ac8c19f2a76a6fd427763/' frameborder='0' width='100%' height='100%'></iframe>
                    </div>
                </div>
            </div>




            {/* Our Story Section */}

            <div className="huddle-page-container-wrapper">
                <h1 className='huddle-header-text'>Our Story</h1>
                <div className="huddle-page-wrapper grid grid-cols-1 md:grid-cols-2 p-7 bg-black gap-5">
                    <div className="image-data-container md:h-80">
                        <img src={cars} alt="Our Story Apexsta Web Developement & Data Analytics , Apexsta Solution, Apexsta UX" srcset="" />
                    </div>
                    <div className="text-side-data-container">
                        <div className="text-container-home-page">
                            <p className='huddle-page-para-text'>We're Apexta, a team brought together by a shared passion for creating impactful digital solutions. </p>
                            <p className='huddle-page-para-text'>Some of us met in the energetic halls of college, others bonded over brainstorming sessions at the office.</p>
                            <p className='huddle-page-para-text'>What united us all was the desire to leverage our diverse skill sets to empower businesses online.</p>
                            <p className='huddle-page-para-text'>From web development wizards to UX design enthusiasts and data-driven strategists, our team offers a comprehensive approach to tackle your digital challenges.</p>
                        </div>
                    </div>
                </div>
            </div>



            {/* Our Team */}

            <div className="our-team-container-wrapper">
                <div className="out-team-container grid grid-cols-1 md:grid-cols-3 p-7 gap-8">
                    <div className="our-team-data-container">
                        <img src={xavi} alt="Mr. Xavier Fernandes , Apexsta UI/UX" srcset="" className='mt-5' />
                        <h3 className="team-member-name">Mr. Xavier Fernandes</h3>
                        <h5 className="team-member-designation mb-5">UX Designer</h5>
                    </div>

                    <div className="our-team-data-container">
                        <img src={hritika} alt="Mrs. Hritika Sharma , Apexsta Consultant ,Apexata Data Analyst" srcset="" className='mt-5' />
                        <h3 className="team-member-name">Mrs. Hritika Sharma</h3>
                        <h5 className="team-member-designation mb-5">Consultant</h5>
                    </div>

                    <div className="our-team-data-container">
                        <img src={developer} alt="Mr. Kalpesh Mangaonkar , Apexsta Full Stack Developer ,Apexata DevOps Engineer" srcset="" className='mt-5' />
                        <h3 className="team-member-name">Mr. Kalpesh Mangaonkar</h3>
                        <h5 className="team-member-designation mb-5">Full Stack Developer</h5>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Huddle
