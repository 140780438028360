import React from 'react';
import { Link } from 'react-scroll';
import linkedin from "../Assets/linkedin.svg"
import facebook from "../Assets/facebook.svg";
import twitter from "../Assets/twitter.svg"

const Footer = () => {
    const handleSmoothScroll = (sectionId) => {
        const section = document.getElementById(sectionId);
        if (section) {
            const y = section.offsetTop;
            const duration = 750; // Adjust duration for desired scrolling speed (milliseconds)
            const easeInOutQuad = (t) => t<.5 ? 2*t*t : -1+(4-2*t)*t;
            let start = null;

            const animate = (time) => {
                if (!start) start = time;
                const progress = time - start;
                const eased = easeInOutQuad(progress / duration);
                window.scrollTo(0, y * eased);
                if (progress < duration) {
                    window.requestAnimationFrame(animate);
                }
            };

            window.requestAnimationFrame(animate);
        }
    };

    return (
        <>
            <footer class="w-full max-w-[85rem] py-10 px-4 sm:px-6 lg:px-8 mx-auto">
                <div class="footer-container text-center rounded-xl p-9">
                    <div class="footer-links-container">
                        {/* Smooth scroll to the "About Us" section */}
                        <Link class="footer-links" to="about-us" smooth={true} duration={500}>About Us</Link>

                        {/* Smooth scroll to the "Services" section */}
                        <Link class="footer-links" to="services" smooth={true} duration={500}>Services</Link>

                        {/* Smooth scroll to the "Contact Us" section */}
                        <Link class="footer-links" to="contact" smooth={true} duration={500}>Contact Us</Link>
                    </div>

                    <div class="mt-3 space-x-2">
                        <Link class="inline-flex justify-center items-center size-10 text-center text-gray-500 hover:bg-gray-100 rounded-full focus:outline-none focus:ring-2 focus:ring-blue-600 focus:ring-offset-2 focus:ring-offset-white transition dark:text-gray-500 dark:hover:text-gray-200 dark:hover:bg-gray-800" href="https://www.linkedin.com/company/apexsta/">
                            <img src={linkedin} alt="Linkedin Apexsta Web Developement & Data Analytics , Apexsta Solution, Apexsta UX" srcset="" />
                        </Link>

                        <Link class="inline-flex justify-center items-center size-10 text-center text-gray-500 hover:bg-gray-100 rounded-full focus:outline-none focus:ring-2 focus:ring-blue-600 focus:ring-offset-2 focus:ring-offset-white transition dark:text-gray-500 dark:hover:text-gray-200 dark:hover:bg-gray-800" href="#">
                            <img src={facebook} alt="Facebook Apexsta Web Developement & Data Analytics , Apexsta Solution, Apexsta UX" srcset="" />
                        </Link>
                        <Link class="inline-flex justify-center items-center size-10 text-center text-gray-500 hover:bg-gray-100 rounded-full focus:outline-none focus:ring-2 focus:ring-blue-600 focus:ring-offset-2 focus:ring-offset-white transition dark:text-gray-500 dark:hover:text-gray-200 dark:hover:bg-gray-800" href="https://www.linkedin.com/company/apexsta/">
                            <img src={twitter} alt="Twitter Apexsta Web Developement & Data Analytics , Apexsta Solution, Apexsta UX" srcset="" />
                        </Link>

                    </div>

                    <div class="mt-3">
                        <p class="text-gray-500">© Apexsta. 2024. All rights reserved.</p>
                    </div>
                </div>
            </footer>
        </>
    );
};

export default Footer;
