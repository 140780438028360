import React from 'react';
import "preline"
import { Link } from 'react-router-dom';
import "./navbar.css";
import apexsta_logo from "../Assets/Logo_apexsta.svg"

const Navbar = () => {
    const handleSmoothScroll = (sectionId) => {
        const section = document.getElementById(sectionId);
        if (section) {
          const y = section.offsetTop;
          const duration = 750; // Adjust duration for desired scrolling speed (milliseconds)
          const easeInOutQuad = (t) => t<.5 ? 2*t*t : -1+(4-2*t)*t;
          let start = null;
    
          const animate = (time) => {
            if (!start) start = time;
            const progress = time - start;
            const eased = easeInOutQuad(progress / duration);
            window.scrollTo(0, y * eased);
            if (progress < duration) {
              window.requestAnimationFrame(animate);
            }
          };
    
          window.requestAnimationFrame(animate);
        }
      };

    return (
        <>
            <header className="flex flex-wrap sm:justify-start sm:flex-nowrap w-full bg-black text-sm py-4">
                <nav className="max-w-[85rem] w-full mx-auto px-8 md:px-4 flex flex-wrap items-center justify-between" aria-label="Global">
                    <div className="flex-none">
                        <Link to="/">
                            <img src={apexsta_logo} alt="Apexsta Logo ,Apexsta Web Developement & Data Analytics , Apexsta Solution, Apexsta UX" className='w-28' />
                        </Link>
                    </div>
                    <div className="flex items-center gap-x-2">
                        <button type="button" className="sm:hidden hs-collapse-toggle p-2.5 inline-flex justify-center items-center gap-x-2 rounded-lg border border-gray-200 bg-white text-gray-800 shadow-sm hover:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none dark:bg-transparent dark:border-gray-700 dark:text-white dark:hover:bg-white/10 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600" data-hs-collapse="#navbar-alignment" aria-controls="navbar-alignment" aria-label="Toggle navigation">
                            <svg className="hs-collapse-open:hidden flex-shrink-0 size-4" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><line x1="3" x2="21" y1="6" y2="6" /><line x1="3" x2="21" y1="12" y2="12" /><line x1="3" x2="21" y1="18" y2="18" /></svg>
                            <svg className="hs-collapse-open:block hidden flex-shrink-0 size-4" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><path d="M18 6 6 18" /><path d="m6 6 12 12" /></svg>
                        </button> 
                    </div>
                    <div id="navbar-alignment" className="hs-collapse hidden overflow-hidden transition-all duration-300 basis-full grow sm:grow-0 sm:basis-auto sm:block sm:order-2">
                        <div className="flex flex-col gap-6 mt-5 sm:flex-row sm:items-center sm:mt-0 sm:ps-5 p-1">
                            <Link className="font-medium nav-link-header" href="#services" onClick={() => handleSmoothScroll('services')}>Services</Link>
                            <Link className="font-medium nav-link-header" href="#about-us"  onClick={() => handleSmoothScroll('about-us')} >About Us</Link>
                            <Link className="font-medium nav-link-header" href="#contact" onClick={() => handleSmoothScroll('contact')}>Contact Us</Link>
                        </div>
                    </div>
                </nav>
            </header>
        </>
    )
}

export default Navbar
