import React from 'react';
import "../../index.css";
import Atropos from 'atropos/react';

const whysceniuz = [
    {

        cardheader: "UX Design",
        cardpara1: "User-centered design that prioritizes usability and customer satisfaction.",
        cardpara2: "Wireframing, prototyping, and user testing to ensure optimal user experience.",
        cardpara3: "Accessibility considerations to make your website inclusive for everyone."
    },
    {
        cardheader: "Website Development",
        cardpara1: "Custom website design and development tailored to your brand and goals.",
        cardpara2: "Mobile-responsive websites that provide a seamless user experience across devices.",
        cardpara3: "Search engine optimization (SEO) to improve your website's visibility online."
    },
    // {
    //     cardheader: "Business Intelligence",
    //     cardpara1: "Transform raw data into actionable insights that drive strategic decision-making.",
    //     cardpara2: "Identify trends and opportunities to maximize your business growth.",
    //     cardpara3: "Customizable dashboards and reports for clear and concise data visualization."
    // },

]

const Services = () => {
    return (
        <>
            <div className="text-our-service-container" id='services'>
                <h1 className='our-services-text'>Our Services</h1>
            </div>

            <div className="data-why-analytics-contyainer-wrapper containerp p-8 grid grid-cols-1 md:grid-cols-2 gap-5">
                {whysceniuz.map((sceniuzdata, index) => (
                    <Atropos
                        activeOffset={40}
                        shadowScale={1.05}
                        onEnter={() => console.log('Enter')}
                        onLeave={() => console.log('Leave')}
                        onRotate={(x, y) => console.log('Rotate', x, y)}
                    >
                        <div key={index} className='card-outline'>
                            <div className="our-services-conatiner">
                                <h5 className="our-service-wrapper">{sceniuzdata.cardheader}</h5>
                                <div className="card-point-container">
                                    <p className="our-service-para">{sceniuzdata.cardpara1}</p>
                                    <p className="our-service-para">{sceniuzdata.cardpara2}</p>
                                    <p className="our-service-para">{sceniuzdata.cardpara3}</p>
                                </div>
                            </div>
                        </div>
                    </Atropos>
                ))}
            </div>

            <div className="card-container-bi pl-8 pr-8">
                
            <Atropos
                activeOffset={40}
                shadowScale={1.05}
                onEnter={() => console.log('Enter')}
                onLeave={() => console.log('Leave')}
                onRotate={(x, y) => console.log('Rotate', x, y)}
            >
                <div className='card-outline-1'>
                    <div className="card-container-wrapper">
                        <h5 className="our-service-wrapper">Business Intelligence</h5>
                        <div className="card-point-container">
                            <p className="our-service-para">Transform raw data into actionable insights that drive strategic decision-making.</p>
                            <p className="our-service-para">Identify trends and opportunities to maximize your business growth.</p>
                            <p className="our-service-para">Customizable dashboards and reports for clear and concise data visualization.</p>
                        </div>
                    </div>
                </div>
            </Atropos>
            </div>

        </>
    )
}

export default Services
