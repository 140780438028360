import React from 'react';
import "../../index.css";
import "./home.css";
import { Link as ScrollLink } from 'react-scroll';
import { Link } from 'react-router-dom';
import green_dot from "../Assets/Rectangle.png";
import arrow from "../Assets/arrow-right.svg";
import blurimage from "../Assets/blurimage.svg";
import Services from './Services';
import Huddle from './Huddle';
import Contact from './Contact';
import Footer from './Footer';

const Home = () => {
    return (
        <>
            <div className="home-page-container-wrapper">
                <div className="home-page-wrapper grid grid-cols-1 md:grid-cols-2 p-7 bg-black">
                    <div className="text-side-data-container">
                        <div className="open-container">
                            <img src={green_dot} alt="Apexsta Web Developement & Data Analytics , Apexsta Solution, Apexsta UX" className='green-dot-image' />
                            <p className='open_for_project_text'>Open for projects</p>
                        </div>
                        <div className="text-container-home-page">
                            <h2 className='home-page-header-text'>Struggling to Bridge the Gap Between Your Vision and Reality?</h2>
                            <p className='home-page-para-text'>We craft strategic solutions to elevate your business online and unlock its full potential.</p>
                        </div>

                        {/* Smooth scroll to Contact section */}
                        <div className="can-we-help-container">
                            <ScrollLink to="contact" smooth={true} duration={500} className='home-page-btn-link-text'>Learn How We Can Help</ScrollLink>
                            <img src={arrow} alt="Apexsta Web Developement & Data Analytics , Apexsta Solution, Apexsta UX" srcset="" className='arrow-image' />
                        </div>

                    </div>
                    <div className="image-data-container">
                        <iframe src='https://my.spline.design/play-47ff017749234264a5295ebc4525bb1b/' frameborder='0' width='100%' height='100%'></iframe>
                    </div>
                </div>

                <Services />
                <Huddle />

                {/* Add an ID to the Contact section */}
                <Contact id="contact" />

                <Footer />
            </div>
        </>
    );
};

export default Home;
